@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&family=Pacifico&display=swap');


// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap-reboot.scss';
@import '~bootstrap/scss/bootstrap-grid.scss';
@import '~bootstrap/scss/bootstrap-utilities.scss';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/accordion';

.no-select {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.bingo-box {
  font-family: "Amatic SC", serif;
  font-weight: 700;
  font-size: 1.6em;
  cursor: pointer;

  transition: background-color ease 0.4s, color ease 0.4s;

  &.readonly {
    cursor: not-allowed;
  }

  background-color: #C7F3DE;

  @media screen and (min-width: 640px) {
    font-size: 1.6em;
  }
  @media screen and (min-width: 1024px) {
    font-size: 2em;
  }
  border-width: 3px 4px 3px 5px;
  border-radius: 42% 2% 31% 2%/2% 94% 5% 91%;
  border: solid #0000003f;
  transform: rotate(0deg);
  min-height: 5em;

  &.checked {
    text-decoration: line-through;
    color: red;
    background-color: #F48B9366;
    cursor: not-allowed;
    //background: #C7F3DE url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><path d='M100 0 L0 100 ' stroke='red' stroke-width='8'/><path d='M0 0 L100 100 ' stroke='red' stroke-width='8'/></svg>") no-repeat center center;
    // background-size: 100% 100%, auto;
  }
}

@-webkit-keyframes rotating /* Safari and Chrome */
{
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotating {
  animation: rotating 2s linear infinite;
}

:root {
  --jumbotron-padding-y: 3rem;
}

.jumbotron {
  padding-top: var(--jumbotron-padding-y);
  padding-bottom: var(--jumbotron-padding-y);
  margin-bottom: 0;
  background-color: #fff;
}

@media (min-width: 768px) {
  .jumbotron {
    padding-top: calc(var(--jumbotron-padding-y) * 2);
    padding-bottom: calc(var(--jumbotron-padding-y) * 2);
  }
}

.jumbotron p:last-child {
  margin-bottom: 0;
}

.jumbotron-heading {
  font-weight: 300;
}

.jumbotron .container {
  max-width: 40rem;
}

footer {
  padding-top: 3rem;
  padding-bottom: 3rem;

  p {
    margin-bottom: .25rem;
  }
}

a {
  text-decoration: none;
}